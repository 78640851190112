.ReactTags__tags {
    position: relative;
}

.ReactTags__tagInput {
    width: 200px;
    display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    padding: 0 4px;
    margin-left: 5px;

    font-family: $input-font-family;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;

    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    @include form-control-focus();

    
    &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }

    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        opacity: 1;
    }
}

.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 5px 5px;
    cursor: move;
    border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 4px;
    cursor: pointer;
}

.ReactTags__suggestions {
    position: absolute;
    margin-top: 5px;
    z-index: 9999;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: #FFFFFF;
    width: 200px;
    border: 0;
    margin: 0;
    padding: 0;
}
  
.ReactTags__suggestions li {
    border: 0;
    padding: 5px 10px;
    background: #FFFFFF;
    margin: 0;
}

.ReactTags__suggestions li mark {
    background: none;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.ReactTags__suggestions .ReactTags__activeSuggestion {
    background: rgba(0, 228, 161, .8);
    cursor: pointer;
}